:root {
  --navbar-height: 4.5rem;
  --content-padding: 1.75rem;
  --layout-border-color: #3a3a3a;
  background-color: #212121;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
